import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Box, Toolbar, IconButton, CssBaseline, Tooltip } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LogoutIcon from "@mui/icons-material/Logout";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InventoryIcon from "@mui/icons-material/Inventory";
import GroupIcon from "@mui/icons-material/Group";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ItemManagement from "../../components/ItemManagement/itemManagement";
import OrderManagement from "../orderManagement/orderManagement";
import AuthForm from "../../forms/signup_form/signup_form";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));



export default function PermanentDrawerLeft() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState("Item Management");
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [role, setRole] = React.useState(null);
  const navigate = useNavigate();

  // Check authentication status from localStorage on component mount
  React.useEffect(() => {
    const storedAuthState = localStorage.getItem("authState");
    if (storedAuthState) {
      const { isAuthenticated, role } = JSON.parse(storedAuthState);
      setIsAuthenticated(isAuthenticated);
      setRole(role);
    }
  }, []);

  // Prevent rendering AuthForm if already authenticated
  React.useEffect(() => {
    if (isAuthenticated) {
      navigate("/"); // Automatically redirect to the dashboard if authenticated
    }
  }, [isAuthenticated, navigate]);

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  const handleItemClick = (item) => setSelectedItem(item);

  const handleAuthSubmit = ({ isAuthenticated, role }) => {
    setIsAuthenticated(isAuthenticated);
    setRole(role);
    const defaultItem =
      role === "super_admin" ? "Item Management" : "Order Management";
    setSelectedItem(defaultItem);

    // Save authentication state to localStorage
    localStorage.setItem("authState", JSON.stringify({ isAuthenticated, role }));
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    setRole(null);
    localStorage.removeItem("authState"); // Remove auth data from localStorage
    navigate("/"); // Redirect to login page
  };

  const roleBasedMenu = {
    super_admin: [
      { text: "Item Management", icon: <InventoryIcon /> },
      { text: "Staff Management", icon: <GroupIcon /> },
      { text: "Order Management", icon: <ShoppingCartIcon /> },
    ],
    admin: [
      { text: "Item Management", icon: <InventoryIcon /> },
      { text: "Order Management", icon: <ShoppingCartIcon /> },
    ],
  };

  const menuItems = role ? roleBasedMenu[role] : [];

  const renderContent = () => {
    switch (selectedItem) {
      case "Item Management":
        return role === "super_admin" || role === "admin" ? (
          <ItemManagement />
        ) : (
          <Box>Access Denied</Box>
        );
      case "Staff Management":
        return role === "super_admin" ? (
          <Box>Staff Management Content</Box>
        ) : (
          <Box>Access Denied</Box>
        );
      case "Order Management":
        return <OrderManagement />;
      default:
        return <Box>Select an option from the menu</Box>;
    }
  };

  if (!isAuthenticated) {
    return <AuthForm onAuthSubmit={handleAuthSubmit} />;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={[{ marginRight: 5 }, open && { display: "none" }]}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title="Logout">
            <IconButton color="inherit" onClick={handleLogout}>
              <LogoutIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />

        <List>
          {menuItems.map(({ text, icon }) => (
            <Tooltip title={text} key={text}>
              <ListItem key={text} disablePadding>
                <ListItemButton
                  onClick={() => handleItemClick(text)}
                  selected={selectedItem === text}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {renderContent()}
      </Box>
    </Box>
  );
}
