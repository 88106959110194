import React from "react";
import { TextField, Button, Box, Typography, Grid } from "@mui/material";

const ContactOrderForm = ({ formData, onChange, onSubmit }) => {
  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      sx={{
        p: 3,
        border: "1px solid #ccc",
        borderRadius: 2,
        maxWidth: 600,
        mx: "auto",
        boxShadow: 2,
        bgcolor: "#f9f9f9",
      }}
    >
      <Typography variant="h6" gutterBottom sx={{ textAlign: "center" }}>
        {formData.id ? "Update Order" : "Add New Order"}
      </Typography>
      <Grid container spacing={3}>
        {[
          { label: "Name", value: formData.name, field: "name", required: true },
          {
            label: "Address",
            value: formData.address,
            field: "address",
            required: true,
          },
          {
            label: "Phone",
            value: formData.phone,
            field: "phone",
            required: true,
            type: "tel",
          },
          { label: "Order Items", value: formData.orderItems, field: "orderItems" },
          {
            label: "Order Quantity",
            value: formData.orderQuantity,
            field: "orderQuantity",
            type: "number",
          },
          {
            label: "Total Price",
            value: formData.totalPrice,
            field: "totalPrice",
            type: "number",
          },
          {
            label: "Payment Status",
            value: formData.paymentStatus,
            field: "paymentStatus",
          },
          {
            label: "Track Status",
            value: formData.trackStatus,
            field: "trackStatus",
          },
        ].map(({ label, value, field, required, type }, index) => (
          <Grid item xs={12} md={field === "orderQuantity" || field === "totalPrice" ? 6 : 12} key={index}>
            <TextField
              fullWidth
              label={label}
              value={value}
              onChange={(e) => onChange(field, e.target.value)}
              required={!!required}
              variant="outlined"
              type={type || "text"}
            />
          </Grid>
        ))}
      </Grid>

      {/* Form Buttons */}
      <Box sx={{ mt: 3, display: "flex", justifyContent: "center", gap: 2 }}>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{ minWidth: 120 }}
        >
          {formData.id ? "Update Order" : "Add Order"}
        </Button>
      </Box>
    </Box>
  );
};

export default ContactOrderForm;
