import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";

const MenuTable = () => {
  const [menuData, setMenuData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Columns for the table
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "item_name", headerName: "Menu Item", width: 200 },
    { field: "item_category", headerName: "Category", width: 150 },
    { field: "item_price", headerName: "Price", width: 150 },
    { field: "item_description", headerName: "Description", width: 300 },
    { field: "item_ingredients", headerName: "Ingredients", width: 200 },
    { field: "item_subcategory", headerName: "Subcategory", width: 150 },
    { field: "item_type", headerName: "Type", width: 150 },
    { field: "menu_name", headerName: "Menu Name", width: 150 },
    { field: "total_price", headerName: "Total Price", width: 150 },
  ];

  // Fetch data from API
  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const response = await fetch(
          "https://bbgrestaurant.com/bbgapi/menu/customer"
        );
        const data = await response.json();
        console.log(data);  // Check the structure of the returned data

        if (data.data) {
          // Transform the data to match the table structure
          const transformedData = data.data.map((item, index) => ({
            id: item.menu_id,  // Unique ID for each row, using menu_id
            item_name: item.item_name,
            item_category: item.item_category.replace(/"/g, ""),  // Clean quotes in item_category
            item_price: Array.isArray(item.item_price) ? item.item_price.join(', ') : item.item_price, // Convert price array to string
            item_description: item.item_description,
            item_ingredients: item.item_ingredients.replace(/"/g, ""),  // Clean quotes in ingredients
            item_subcategory: item.item_subcategory,
            item_type: item.item_type || "N/A",  // Handle empty or missing type
            menu_name: item.menu_name,
            total_price: item.total_price,
          }));

          setMenuData(transformedData);
        } else {
          console.error("Menu data is not in the expected format:", data);
        }
      } catch (error) {
        console.error("Error fetching menu data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMenuData();
  }, []);

  return (
    <Box sx={{ height: 400, width: "100%", mt: 2 }}>
      <Typography sx={{ textAlign: "center", fontWeight: "bold", mb: 2 }}>
        Menu Table
      </Typography>
      <DataGrid
        rows={menuData}  // Use the transformed data
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 15]}
        loading={loading}
        disableSelectionOnClick
      />
    </Box>
  );
};

export default MenuTable;
