import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  TextField,
} from "@mui/material";
import axios from "axios";
import PaginationControl from "./pagination";
import TableHeader from "./tableHeader";
import OrderRow from "./orderRow";

const PaginatedOrderTable = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [selectedTime, setSelectedTime] = useState("00:00");
  const [searchTrackingId, setSearchTrackingId] = useState("");

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(
          "https://www.bbgrestaurant.com/bbgapi/joinOrder"
        );
        const fetchedOrders = response.data.data[0] || [];

        // Get stored fetch times
        const storedTimestamps = JSON.parse(
          localStorage.getItem("orderFetchTimes") || "{}"
        );

        // Update fetch times for only new orders
        const updatedTimestamps = { ...storedTimestamps };
        fetchedOrders.forEach((order) => {
          if (!updatedTimestamps[order.order_id]) {
            // Only assign a fetch time if it doesn't already exist
            updatedTimestamps[order.order_id] = new Date().toLocaleString();
          }
        });

        // Save updated timestamps to localStorage
        localStorage.setItem(
          "orderFetchTimes",
          JSON.stringify(updatedTimestamps)
        );

        // Enrich orders with fetch time
        const enrichedOrders = fetchedOrders.map((order) => ({
          ...order,
          fetchTime: updatedTimestamps[order.order_id],
        }));

        setOrders(enrichedOrders);
        filterOrdersByDateAndTime(enrichedOrders, selectedDate, selectedTime);
      } catch (error) {
        console.error("Error fetching orders:", error);
        setOrders([]);
      }
    };

    fetchOrders();
  }, [selectedDate, selectedTime]);

  const filterOrdersByDateAndTime = (orders, date, time) => {
    const filtered = orders.filter((order) => {
      // Ensure fetchTime is a valid Date object
      const fetchDate = new Date(order.fetchTime);
      const formattedFetchDate = fetchDate.toISOString().split("T")[0]; // Only date part
      return formattedFetchDate === date; // Compare fetch date with the selected date
    });
    setFilteredOrders(filtered);
  };

  const handleDateChange = (e) => {
    const date = e.target.value;
    setSelectedDate(date);
    filterOrdersByDateAndTime(orders, date, selectedTime);
  };

  const handleTimeChange = (e) => {
    const time = e.target.value;
    setSelectedTime(time);
    filterOrdersByDateAndTime(orders, selectedDate, time);
  };
  const handleTrackingIdSearch = (e) => {
    const searchValue = e.target.value;
    setSearchTrackingId(searchValue);

    if (searchValue) {
      const filtered = orders.filter((order) =>
        order.tracking_id?.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredOrders(filtered);
    } else {
      setFilteredOrders(orders); // Reset to all orders if search is cleared
    }
  };
  const updateOrderStatus = async (status_id, newStatus) => {
    try {
      const response = await axios.patch(
        `https://www.bbgrestaurant.com/bbgapi/orderStatus/${status_id}`,
        { track_status: newStatus }
      );

      if (response.status !== 200) {
        throw new Error("Failed to update status");
      }

      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.status_id === status_id
            ? { ...order, track_status: newStatus }
            : order
        )
      );

      alert("Order status updated successfully");
    } catch (error) {
      console.error("Error updating status:", error);
      alert("Error updating order status. Please try again.");
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        p: 2,
        overflowX: "scroll",
        fontSize: {
          xs: "4px",
          sm: "6px",
          md: "8px",
          lg: "8px",
        },
      }}
    >
      <TextField
        label="Search by Tracking ID"
        value={searchTrackingId}
        onChange={handleTrackingIdSearch}
        sx={{ mb: 2 }}
        fullWidth
      />
      <TextField
        label="Filter by Date"
        type="date"
        value={selectedDate}
        onChange={handleDateChange}
        sx={{ mb: 2 }}
        InputLabelProps={{ shrink: true }}
      />
      <TextField
        label="Filter by Time"
        type="time"
        value={selectedTime}
        onChange={handleTimeChange}
        sx={{ mb: 2, ml: 2 }}
        InputLabelProps={{ shrink: true }}
      />
      <Paper>
        <TableContainer sx={{ width: "100%" }}>
          <Table>
            <TableHeader />
            <TableBody>
              {filteredOrders.length === 0 ? (
                <TableRow>
                  <TableCell
                    colSpan={16}
                    align="center"
                    sx={{ fontSize: "4px" }}
                  >
                    No orders found for the provided tracking ID.
                  </TableCell>
                  <TableCell
                    colSpan={16}
                    align="center"
                    sx={{ fontSize: "4px" }}
                  >
                    No orders found for the selected date and time.
                  </TableCell>
                </TableRow>
              ) : (
                filteredOrders
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((order, index) => (
                    <OrderRow
                      key={order.status_id || index}
                      order={order}
                      index={index}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      fetchTime={order.fetchTime}
                      onStatusUpdate={updateOrderStatus}
                    />
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationControl
          items={filteredOrders}
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default PaginatedOrderTable;
