import React from "react";
import { Box, Typography, Avatar } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

const FormHeader = ({ title }) => (
  <Box display="flex" flexDirection="column" alignItems="center">
    <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
      <LockOutlinedIcon />
    </Avatar>
    <Typography component="h1" variant="h5">
      {title}
    </Typography>
  </Box>
);

export default FormHeader;
