import React, { useState, useEffect } from "react";
import {
  TableRow,
  TableCell,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

const OrderRow = ({
  order,
  index,
  page,
  rowsPerPage,
  fetchTime,
  onStatusUpdate,
}) => {
  // Initialize the status with the saved value or default value
  const savedStatus =
    localStorage.getItem(`order-status-${order.status_id}`) ||
    order.track_status ||
    "Cooking";

  const [status, setStatus] = useState(savedStatus);

  useEffect(() => {
    // Sync the status to localStorage whenever it changes
    localStorage.setItem(`order-status-${order.status_id}`, status);
  }, [status, order.status_id]);

  const handleStatusChange = (event) => {
    const newStatus = event.target.value;
    setStatus(newStatus);

    // Call the status update function passed from the parent
    if (onStatusUpdate) {
      onStatusUpdate(order.status_id, newStatus);
    }
  };

  const parseCustomerDetails = (key) => {
    try {
      const customer = JSON.parse(order.customer_detail);
      return customer[key] || `No ${key} provided`;
    } catch {
      return "Invalid data";
    }
  };

  return (
    <TableRow>
      <TableCell>{page * rowsPerPage + index + 1}</TableCell>
      <TableCell>
        <Tooltip title={`Fetched at ${fetchTime}`}>
          <span>{fetchTime}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title={order.customer_detail || "No details"}>
          <span>{parseCustomerDetails("name")}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title={order.customer_detail || "No details"}>
          <span>{parseCustomerDetails("address")}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title="Click to call this number">
          <span>{parseCustomerDetails("phone")}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title={order.customer_detail || "No email"}>
          <span>{parseCustomerDetails("email")}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title={order.order_instructions || "No instructions"}>
          <span>{order.order_instructions || "No instructions"}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip
          title={order.order_address_instruction || "No address instructions"}
        >
          <span>
            {order.order_address_instruction || "No address instructions"}
          </span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip
          title={(() => {
            try {
              const items = JSON.parse(order.order_items);
              return items
                .map((item) => `${item.name}=${item.quantity}`)
                .join(", ");
            } catch {
              return "Invalid data";
            }
          })()}
        >
          <span>
            {(() => {
              try {
                const items = JSON.parse(order.order_items);
                return items
                  .map((item) => `${item.name}=${item.quantity}`)
                  .join(", ");
              } catch {
                return "Invalid data";
              }
            })()}
          </span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title={order.order_quantity || "N/A"}>
          <span>{order.order_quantity || "N/A"}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title={order.total_price || "N/A"}>
          <span>{order.total_price || "N/A"}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title={order.table_no || "N/A"}>
          <span>{order.table_no || "N/A"}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title={order.tracking_id || "N/A"}>
          <span>{order.tracking_id || "N/A"}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <FormControl fullWidth>
          <InputLabel>Status</InputLabel>
          <Select
            value={status} // Bind the `status` state directly to the `value` prop
            onChange={handleStatusChange}
            sx={{ fontSize: "12px" }}
          >
            <MenuItem value="Pending">Pending</MenuItem>
            <MenuItem value="Order Accepted">Order Accepted</MenuItem>
            <MenuItem value="Cooking">Cooking</MenuItem>
            <MenuItem value="Packing">Packing</MenuItem>
            <MenuItem value="On the Way">On the Way</MenuItem>
            <MenuItem value="Delivered">Delivered</MenuItem>
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        <Tooltip title={order.payment_mode || "N/A"}>
          <span>{order.payment_mode || "N/A"}</span>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Tooltip title={order.payment_status || "N/A"}>
          <span>{order.payment_status || "N/A"}</span>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

export default OrderRow;
