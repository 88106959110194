import React from "react";
import { Box, TextField, Button } from "@mui/material";

const SignInForm = ({
  formData,
  showPassword,
  handleInputChange,
  handleSignIn,
  setShowPassword,
}) => (
  <Box component="form" onSubmit={handleSignIn} sx={{ mt: 3 }}>
    <TextField
      variant="outlined"
      margin="normal"
      required
      fullWidth
      id="admin_contact"
      label="Email or Phone"
      name="admin_contact"
      value={formData.admin_contact}
      onChange={handleInputChange}
    />
    <TextField
      variant="outlined"
      margin="normal"
      required
      fullWidth
      name="admin_password"
      label="Password"
      type={showPassword ? "text" : "password"}
      value={formData.admin_password}
      onChange={handleInputChange}
      InputProps={{
        endAdornment: (
          <Button onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? "Hide" : "Show"}
          </Button>
        ),
      }}
    />
    <Button
      type="submit"
      fullWidth
      variant="contained"
      sx={{ mt: 3, mb: 2 }}
    >
      Sign In
    </Button>
  </Box>
);

export default SignInForm;
