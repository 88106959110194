import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";

const ContactOrderTable = ({ rows, loading, onEdit }) => {
  
  return (
    
    <TableContainer component={Paper} sx={{ overflowX: "scroll", mt: 4 }}>
      <Table>
        <TableHead>
          <TableRow>
            {[
              "S.N",
              "Date and Time",
              "Name",
              "Address",
              "Phone",
              "Order Items",
              "Order Quantity",
              "Total Price",
              "Payment Status",
              "Track Status",
              "Action",
            ].map((header) => (
              <TableCell
                key={header}
                sx={{
                  fontWeight: "bold",
                  fontSize: {
                    xs: "4px",
                    sm: "8px",
                    md: "10px",
                    lg: "14px",
                  },
                }}
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={10} align="center">
                Loading...
              </TableCell>
            </TableRow>
          ) : rows.length > 0 ? (
            rows.map((row, index) => (
              <TableRow key={row.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  {" "}
                  {new Date(row.timestamp).toLocaleString()}{" "}
                </TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.address}</TableCell>
                <TableCell>{row.contact_number}</TableCell>
                <TableCell>{row.order_items}</TableCell>
                <TableCell>{row.order_quantity}</TableCell>
                <TableCell>{row.total_price}</TableCell>
                <TableCell>{row.payment_status}</TableCell>
                <TableCell>{row.track_status}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onEdit(row)}
                    sx={{
                      fontSize: {
                        xs: "4px",
                        sm: "6px",
                        md: "8px",
                        lg: "10px",
                      },
                    }}
                  >
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={10} align="center">
                No data available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ContactOrderTable;
