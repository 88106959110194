import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import ItemImage from "./itemImage";
import StatusField from "./itemStatus";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

const truncate = (text, maxLength) => {
  return text.length > maxLength ? `${text.slice(0, maxLength)}... ` : text;
};

const EditableTableRow = ({ index, item, deleteItem, updateItem }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [editedItem, setEditedItem] = useState({
    ...item,
    item_ingredients: Array.isArray(JSON.parse(item.item_ingredients))
      ? JSON.parse(item.item_ingredients)
      : [],
    item_price: Array.isArray(JSON.parse(item.item_price))
      ? JSON.parse(item.item_price)
      : [0, 0], // Ensure it's an array of two elements
  });
  const [imageFile, setImageFile] = useState(null);

  const handleFieldChange = (field, value) => {
    setEditedItem((prevItem) => ({ ...prevItem, [field]: value }));
  };

  const handleIngredientsChange = (index, value) => {
    const updatedIngredients = [...editedItem.item_ingredients];
    updatedIngredients[index] = value;
    setEditedItem({ ...editedItem, item_ingredients: updatedIngredients });
  };

  const addIngredient = () => {
    setEditedItem((prevItem) => ({
      ...prevItem,
      item_ingredients: [...prevItem.item_ingredients, ""],
    }));
  };

  const removeIngredient = (index) => {
    setEditedItem((prevItem) => {
      const updatedIngredients = [...prevItem.item_ingredients];
      updatedIngredients.splice(index, 1);
      return { ...prevItem, item_ingredients: updatedIngredients };
    });
  };

  const handleEditClick = () => setIsDialogOpen(true);

  const handleSaveClick = async () => {
    try {
      const ingredients = editedItem.item_ingredients.map((v) => v.trim());
      const formData = new FormData();

      // Append ingredients
      formData.append("item_ingredients", JSON.stringify(ingredients));

      // Ensure item_price is properly passed as an array
      const itemPrice = [
        parseFloat(editedItem.item_price?.[0]) || 0, // Day price, ensure it's a number
        parseFloat(editedItem.item_price?.[1]) || 0, // Night price, ensure it's a number
      ];

      // Append the item_price as an array
      formData.append("item_price", JSON.stringify(itemPrice));

      // Append other fields excluding item_ingredients and item_price
      Object.entries(editedItem).forEach(([key, value]) => {
        if (key !== "item_ingredients" && key !== "item_price")
          formData.append(key, value);
      });

      // Append the image file if it's provided
      if (imageFile) formData.append("item_image", imageFile);

      // Send the updated data to the updateItem function (API call)
      await updateItem(item.sid, formData);
      setIsDialogOpen(false); // Close the dialog after saving
    } catch (error) {
      console.error("Error updating item:", error);
    }
  };

  const handleCancelClick = () => {
    setIsDialogOpen(false);
    setEditedItem({ ...item });
    setImageFile(null);
  };

  return (
    <>
      <TableRow
        sx={{ "&:hover": { backgroundColor: "rgba(0, 0, 255, 0.05)" } }}
      >
        <TableCell>{index + 1}</TableCell>
        {[
          { field: "item_name", maxLength: 20 },
          { field: "item_category", maxLength: 20 },
          { field: "item_subcategory", maxLength: 20 },
          { field: "item_type", maxLength: 20 },
          { field: "item_description", maxLength: 15 },
          { field: "item_ingredients", maxLength: 15 },
        ].map(({ field, maxLength }) => (
          <TableCell key={field}>
            <Tooltip title={item[field] || "N/A"}>
              <span>{truncate(item[field] || "N/A", maxLength)}</span>
            </Tooltip>
          </TableCell>
        ))}
        <TableCell>
          <ItemImage
            isEditing={false}
            editedItem={editedItem}
            item={item}
            setEditedItem={setEditedItem}
          />
        </TableCell>
        <TableCell>{item.item_price || "N/A"}</TableCell>
        {[
          "item_popular",
          "item_special",
          "item_offer_festival",
          "item_instant_offer",
        ].map((statusField) => (
          <TableCell key={statusField}>
            <StatusField
              isEditing={false}
              statusField={statusField}
              item={item}
              editedItem={editedItem}
              setEditedItem={setEditedItem}
            />
          </TableCell>
        ))}
        <TableCell>
          <IconButton onClick={handleEditClick}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => deleteItem(item)}>
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>

      <Dialog open={isDialogOpen} onClose={handleCancelClick} fullWidth>
        <DialogTitle>Edit Item</DialogTitle>
        <DialogContent>
          {[
            { label: "Item Name", field: "item_name" },
            { label: "Category", field: "item_category" },
            { label: "Subcategory", field: "item_subcategory" },
            { label: "Type", field: "item_type" },
          ].map(({ label, field }) => (
            <TextField
              key={field}
              label={label}
              value={editedItem[field] || ""}
              onChange={(e) => handleFieldChange(field, e.target.value)}
              fullWidth
              margin="dense"
            />
          ))}

          <ItemImage
            isEditing={true}
            editedItem={editedItem}
            item={item}
            setEditedItem={setEditedItem}
            setImageFile={setImageFile}
          />

          <div style={{ marginTop: "16px" }}>
            <label style={{ fontWeight: "bold" }}>Ingredients</label>
            {(editedItem.item_ingredients &&
            Array.isArray(editedItem.item_ingredients)
              ? editedItem.item_ingredients
              : []
            ).map((ingredient, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "8px",
                }}
              >
                <TextField
                  value={ingredient}
                  onChange={(e) =>
                    handleIngredientsChange(index, e.target.value)
                  }
                  fullWidth
                  margin="dense"
                />
                <IconButton onClick={() => removeIngredient(index)}>
                  <RemoveCircleIcon color="error" />
                </IconButton>
              </div>
            ))}

            <IconButton onClick={addIngredient}>
              <AddCircleIcon color="primary" />
            </IconButton>
          </div>

          {/* Render Day and Night Price Fields */}
          <div style={{ marginTop: "16px" }}>
            <TextField
              label="Day Price"
              type="number"
              value={editedItem.item_price?.[0] || ""}
              onChange={(e) =>
                handleFieldChange("item_price", [
                  e.target.value,
                  editedItem.item_price[1], // Keep night price unchanged
                ])
              }
              fullWidth
              margin="dense"
            />
            <TextField
              label="Night Price"
              type="number"
              value={editedItem.item_price?.[1] || ""}
              onChange={(e) =>
                handleFieldChange("item_price", [
                  editedItem.item_price[0], // Keep day price unchanged
                  e.target.value,
                ])
              }
              fullWidth
              margin="dense"
              style={{ marginTop: "16px" }}
            />
          </div>

          {[
            { label: "Item Popular", field: "item_popular" },
            { label: "Item Special", field: "item_special" },
            { label: "Item Offer Festival", field: "item_offer_festival" },
            { label: "Item Instant Offer", field: "item_instant_offer" },
          ].map(({ label, field }) => (
            <div key={field} style={{ marginTop: "16px" }}>
              <label style={{ fontWeight: "bold" }}>{label}</label>
              <StatusField
                isEditing={true}
                statusField={field}
                item={item}
                editedItem={editedItem}
                setEditedItem={setEditedItem}
              />
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClick} startIcon={<CancelIcon />}>
            Cancel
          </Button>
          <Button
            onClick={handleSaveClick}
            startIcon={<SaveIcon />}
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditableTableRow;
