import React, { useState, useEffect, useMemo } from "react";
import { TextField, Checkbox, IconButton, Box } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";

const StatusField = ({
  isEditing,
  statusField,
  item,
  editedItem,
  setEditedItem,
  id, // Pass the item's ID for API calls
}) => {
  const parseJSONSafely = (str, fallback) => {
    if (!str) return fallback; // Handle null or undefined
    try {
      return JSON.parse(str);
    } catch {
      return fallback;
    }
  };

  // Memoize the parsed status to avoid recreating it on every render
  const status = useMemo(() => {
    const parsed = parseJSONSafely(item[statusField], []);
    // If parsed data is not an array or doesn't have the expected structure, return a default status object
    return Array.isArray(parsed) && parsed.length > 0
      ? parsed[0]
      : { status: false, "start time": "", "end time": "" };
  }, [item, statusField]);

  const [checkboxStatus, setCheckboxStatus] = useState(status.status);
  const [startTime, setStartTime] = useState(status["start time"]);
  const [endTime, setEndTime] = useState(status["end time"]);
  const [error, setError] = useState("");

  useEffect(() => {
    setCheckboxStatus(status.status);
    setStartTime(status["start time"]);
    setEndTime(status["end time"]);
  }, [status]);

  const updateDatabase = async () => {
    try {
      if (checkboxStatus && (!startTime || !endTime)) {
        setError(
          "Start Time and End Time are required when the checkbox is selected."
        );
        return;
      }
      setError(""); // Clear errors if validation passes
      const updatedStatus = JSON.stringify([
        { status: checkboxStatus, "start time": startTime, "end time": endTime },
      ]);

      // Update parent state with the new status
      setEditedItem((prevItem) => ({
        ...prevItem,
        [statusField]: updatedStatus,
      }));

      // Call API to update database
      await axios.patch(
        `https://www.bbgrestaurant.com/bbgapi/itemstatus/${id}`,
        {
          status: checkboxStatus,
          "start time": startTime,
          "end time": endTime,
        }
      );

      alert("Status updated successfully!");
    } catch (error) {
      console.error("Error updating status:", error);
      alert("Failed to update status. Please try again.");
    }
  };

  return isEditing ? (
    <Box>
      <Checkbox
        checked={checkboxStatus}
        onChange={(e) => {
          setCheckboxStatus(e.target.checked);
          if (!e.target.checked) {
            setStartTime(""); // Clear start time if unchecked
            setEndTime(""); // Clear end time if unchecked
          }
        }}
        sx={{
          color: "blue",
          "&.Mui-checked": {
            color: "blue",
          },
        }}
      />
      {checkboxStatus && (
        <Box sx={{ mt: 2 }}>
          <TextField
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            label="Start Time"
            size="small"
            fullWidth
            variant="outlined"
            sx={{
              backgroundColor: "rgba(173, 216, 230, 0.3)",
              "&:focus": {
                backgroundColor: "white",
              },
            }}
          />
          <TextField
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            label="End Time"
            size="small"
            fullWidth
            variant="outlined"
            sx={{
              mt: 2,
              backgroundColor: "rgba(173, 216, 230, 0.3)",
              "&:focus": {
                backgroundColor: "white",
              },
            }}
          />
        </Box>
      )}
      {error && (
        <Box sx={{ color: "red", mt: 1, fontSize: "14px" }}>{error}</Box>
      )}
      <IconButton
        onClick={updateDatabase}
        color="primary"
        sx={{ mt: 2 }}
      >
        <SaveIcon />
      </IconButton>
    </Box>
  ) : (
    <Box>
      {checkboxStatus
        ? `${startTime || "N/A"} : ${endTime || "N/A"}`
        : "False"}
    </Box>
  );
};

export default StatusField;
