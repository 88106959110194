import React from "react";
import { Box, TextField, Button } from "@mui/material";

const ResetPasswordForm = ({
  formData,
  otpSent,
  otpVerified,
  handleInputChange,
  handleOtpSend,
  handleOtpVerify,
  handlePasswordReset,
}) => (
  <Box
    component="form"
    onSubmit={
      otpVerified
        ? handlePasswordReset
        : otpSent
        ? handleOtpVerify
        : handleOtpSend
    }
    sx={{ mt: 3 }}
  >
    <TextField
      variant="outlined"
      margin="normal"
      required
      fullWidth
      id="admin_contact"
      label="Email or Phone"
      name="admin_contact"
      value={formData.admin_contact}
      onChange={handleInputChange}
      disabled={otpSent || otpVerified}
    />
    {otpSent && !otpVerified && (
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="otp"
        label="Enter OTP"
        name="otp"
        value={formData.otp}
        onChange={handleInputChange}
      />
    )}
    {otpVerified && (
      <>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="new_password"
          label="New Password"
          type="password"
          value={formData.new_password}
          onChange={handleInputChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="confirm_password"
          label="Confirm Password"
          type="password"
          value={formData.confirm_password}
          onChange={handleInputChange}
        />
      </>
    )}
    <Button
      type="submit"
      fullWidth
      variant="contained"
      sx={{ mt: 3, mb: 2 }}
    >
      {otpVerified
        ? "Reset Password"
        : otpSent
        ? "Verify OTP"
        : "Send OTP"}
    </Button>
  </Box>
);

export default ResetPasswordForm;
